.letters {

}

.letters.active {

}

.letter {
  opacity: 0;
  /*transition: all 200ms;*/
  position:relative;
  color:  #9641C7;
  animation-name: show ;
  animation-duration: 200ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes show {
  0% { opacity: 0 }
  100% { opacity: 1; color: inherit }

}
