@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
@import url('https://rsms.me/inter/inter.css');

html {
  font-family: 'Inter', sans-serif; 
  color: #4A4A4A;
  position: relative;
}

* {
  border:0;
  padding: 0;
  margin: 0;
  outline: none;
  box-sizing: border-box;
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: inherit;
}
