@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap);
@import url(https://rsms.me/inter/inter.css);
html {
  font-family: 'Inter', sans-serif; 
  color: #4A4A4A;
  position: relative;
}

* {
  border:0;
  padding: 0;
  margin: 0;
  outline: none;
  box-sizing: border-box;
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.letters {

}

.letters.active {

}

.letter {
  opacity: 0;
  /*transition: all 200ms;*/
  position:relative;
  color:  #9641C7;
  animation-name: show ;
  animation-duration: 200ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes show {
  0% { opacity: 0 }
  100% { opacity: 1; color: inherit }

}

